<template>
  <div class="main">
    <label :style="labelStyle">{{(value && value.length) || '0'}}个设备</label>&nbsp;&nbsp;<el-button type="primary" size="small" @click="open">设置</el-button>
    <el-dialog :title="this.title || '高级场景设置'" :close-on-click-modal="false" :visible.sync="visible" class="dialog" draggable="false">
      <div class="dialog-page">
        <el-table ref="stb" :data="list" class="selectdata-table">
          <el-table-column header-align="center" align="center" label="受控设备" min-width="180">
            <template slot-scope="scope">
              <img v-if="scope.row.brand && scope.row.brand.img" class="label-img" 
                :src="$imgPath+scope.row.brand.img" />
              <el-select v-model="scope.row.brand" class="required" value-key="id" placeholder="请选择受控设备" @change="brandChangeAndClear(scope.row,scope.$index)" >
                  <el-option v-for="(option,index) in brandData" 
                  :key="index" :label="option.name" :value="option">
                    <span style="float: left"><img style="width:26px" v-if="option" :src="$imgPath+option.img" /></span>
                    <span style="float: right;">{{option && option.name}}</span>
                  </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" label="名称" >
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" placeholder="请输入名称"></el-input>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" label="子设备" >
            <template slot-scope="scope">
              <el-select class="required" v-if="scope.row.brand && (scope.row.brand.keytype == 5 || scope.row.brand.keytype == 129)" v-model="scope.row.button" value-key="id" placeholder="请选择子设备" @change="btn1ChangeAndClear(scope.row,scope.$index)">
                  <el-option v-for="(option,index) in optionsData.list[scope.$index].buttons1 || []"
                      :key="index" :label="option.name" :value="option"></el-option>
              </el-select>
              <el-select class="required" v-if="scope.row.brand && (scope.row.brand.keytype == 11 || scope.row.brand.keytype == 130)" v-model="scope.row.button" value-key="id" placeholder="请选择子设备" @change="btn2ChangeAndClear(scope.row,scope.$index)">
                  <el-option v-for="(option,index) in optionsData.list[scope.$index].buttons2 || []"
                      :key="index" :label="option.name" :value="option"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" label="动作" >
            <template slot-scope="scope">
              <el-select class="required" v-model="scope.row.commandbytes" filterable allow-create value-key="name" placeholder="请选择动作" @change="cbChange(scope.row)">
                  <el-option v-for="(option,index) in optionsData.list[scope.$index].commandbytes || []"
                      :key="index" :label="option.name" :value="option"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" label="变量复选" >
            <template slot-scope="scope">
              <select-vars class="required" v-model="scope.row.vars" :brand-data="scope.row.brand" :button-data="scope.row.button"></select-vars>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" width="70" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="copyItem(scope.$index)">复制</el-button>
              <el-button type="text" size="small" @click="delItem(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" style="margin-top:10px" @click="addItem()">添加动作</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="ok()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Sortable from 'sortablejs'
  import clone from 'lodash/cloneDeep'
  import { formatCode } from '@/utils'
  import selectVars from '@/components/select-vars'
  export default {
    components: {
      selectVars
    },
    props: ['value', 'systemId', 'title'],
    data () {
      return {
        list: [
  
        ],
        visible: false,
        optionsData: {
          list: [] // 条目中各自的下拉数据
        },
        brandData: null,
        loadSystemId: null// 已经加载了的系统id
  
      }
    },
    computed: {
      labelStyle: function() {
        if (this.value && this.value.length) {
          for (var v of this.value) {
            if (!v.brand || !v.button || !v.commandbytes) {
              return {color: 'red'}
            }
            if (v.vars && v.vars.length > 0) {
              for (var item of v.vars) {
                if (item == null) {
                  return {color: 'red'}
                }
              }
            }
          }
        }
        return {}
      }
    },
    methods: {
      open() {
        this.init()
        this.visible = true
  
        this.$nextTick(() => {
          this.setSort()
        })
      },
      init() {
        if (this.value) {
          this.list = this.value
          if (this.optionsData.list.length < 1 && this.list.length > 0) {
            for (var item of this.list) {
              var index = this.list.indexOf(item)
              this.optionsData.list.push({})
              this.brandChange(item, index)
              if (Number(item.brand.keytype) === 5) {
                this.btn1Change(item, index)
              } else {
                this.btn2Change(item, index)
              }
            }
          }
        } else {
          this.list = []
          this.optionsData = {list: []}
        }
        if (this.loadSystemId !== this.systemId) {
          this.getBrandData()
        }
      },
      ok() {
        this.$emit('input', this.list)
        this.visible = false
      },
      addItem() {
        this.list.push({})
        this.optionsData.list.push({})
        this.setSort()
      },
      delItem(index) {
        this.list.splice(index, 1)
        this.optionsData.list.splice(index, 1)
        this.setSort()
      },
      copyItem(index) {
        this.optionsData.list.push(clone(this.optionsData.list[index]))
        this.list.push(clone(this.list[index]))
        this.setSort()
      },
      // 获取品牌数据
      getBrandData() {
        this.$http({
          url: this.$http.adornUrl('/bus/busbrand/getMyBrandList'),
          method: 'get',
          params: {
            'systemId': this.systemId
          }
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.loadSystemId = this.systemId
            this.brandData = data.list
          }
        })
      },
      brandChangeAndClear(row, index) {
        this.brandChange(row, index)
        this.$set(this.list[index], 'button', null)
        this.$set(this.list[index], 'vars', [])
      },
      // 品牌数据改变后
      brandChange(row, index) {
        var brand = row.brand
        if (!brand) return
        this.$http({
          url: this.$http.adornUrl('/bus/busbuttons/getListByBrandId'),
          method: 'post',
          data: brand.id
        }).then(({data}) => {
          if (data && data.code === 0) {
            var buttons1 = []
            var buttons2 = []
            for (var btn of data.list) {
              if (Number(btn.pid) === 0)buttons1.push(btn)
              else buttons2.push(btn)
            }
            this.$set(this.optionsData.list[index], 'buttons1', buttons1)
            this.$set(this.optionsData.list[index], 'buttons2', buttons2)
          } else {
            this.$set(this.optionsData.list[index], 'buttons1', [])
            this.$set(this.optionsData.list[index], 'buttons2', [])
          }
        })
      },
      btn1ChangeAndClear(row, index) {
        this.btn1Change(row, index)
        this.$set(this.list[index], 'commandbytes', null)
        this.$set(this.list[index], 'vars', [])
      },
      btn1Change(row, index) {
        if (!row.button) return
        this.$set(this.optionsData.list[index], 'commandbytes', [{
          name: '默认开',
          value: row.button.commandbytes1
        }, {
          name: '默认关',
          value: row.button.commandbytes
        }])
      },
      btn2ChangeAndClear(row, index) {
        this.btn2Change(row, index)
        this.$set(this.list[index], 'commandbytes', null)
        this.$set(this.list[index], 'vars', [])
      },
      btn2Change(row, index) {
        if (!row.button) return
        this.$http({
          url: this.$http.adornUrl('/bus/busbuttons/getListByPid'),
          method: 'post',
          data: row.button.id
        }).then(({data}) => {
          if (data && data.code === 0) {
            var buttons = []
            for (var btn of data.list) {
              buttons.push({
                name: btn.name,
                value: btn.commandbytes
              })
            }
            this.$set(this.optionsData.list[index], 'commandbytes', buttons)
          }
        })
      },
      setSort() {
        const el = this.$refs.stb.$el.querySelectorAll('.main .el-table__body-wrapper > table > tbody')[0]
        Sortable.create(el, {
          ghostClass: 'sortable-ghost2', // Class name for the drop placeholder,
          onEnd: evt => {
            let list = this.list
            const targetRow = list.splice(evt.oldIndex, 1)[0]
            list.splice(evt.newIndex, 0, targetRow)
            const optionsDataListRow = this.optionsData.list.splice(evt.oldIndex, 1)[0]
            this.optionsData.list.splice(evt.newIndex, 0, optionsDataListRow)
            this.list = []
            this.$nextTick(() => {
              this.list = list
            })
          }
        })
      },
      cbChange(row) {
        this.$set(row, 'commandbytes', formatCode(row.commandbytes))
      }
    }
  }
</script>
<style scoped lang="scss">
.main{
  flex:1;
  justify-content: center;
  align-items: center;
}
.dialog{
  ::v-deep .el-dialog{
    width: 80%;
  }
}
.selectdata-table{
  ::v-deep .cell{
    justify-content: center;
  }
}
.dialog-page{
  text-align: left;
}
</style>
