<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible" class="brand">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm"  label-width="100px">
    <el-form-item label="图片" prop="img">
      <upload-img v-model="dataForm.img"/>
    </el-form-item>
    <el-form-item label="所属类别" prop="deviceType">
      <el-select v-model="dataForm.deviceType" clearable placeholder="所属类别" >
          <el-option v-for="(dt,index) in getDtdata()" :key="index" :label="dt.title" :value="dt.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
    </el-form-item>
    
    <el-form-item label="按钮类型" prop="deviceType">
      <el-select v-model="dataForm.keytype" placeholder="按钮类型" >
        <el-option v-for="(option,index) in optionsData.keyType || []" 
                    :key="index" :label="option.name" :value="option.id"></el-option>
      </el-select>
      
    </el-form-item>
    <el-form-item label="波特率备注" prop="remark">
      <el-input v-model="dataForm.remark" placeholder="波特率备注"></el-input>
    </el-form-item>
    <el-form-item label="链接地址">
      <el-input v-model="dataForm.link" placeholder="链接地址"></el-input>
    </el-form-item>
    <el-form-item label="排序号" style="width:100%">
      <el-input-number v-model="dataForm.sort" :min="0" label="排序号"></el-input-number>
    </el-form-item>
    <el-form-item label="开启高级" v-show="!dataForm.id && isAuth('bus:busbrand:extend')">
      <el-switch v-model="dataForm.isextend" />
    </el-form-item>
    <div style="width:100%" v-if="dataForm.isextend">
      <el-form-item label="按钮数据格式" style="width:100%">
        <div class="e-row">
          <div class="e-col" v-for="(d,i) in dataForm.extenddata.datas" :key="i">
            <el-input v-model="dataForm.extenddata.titles[i]" clearable placeholder="标签"></el-input>
            <el-select v-model="dataForm.extenddata.datas[i]" clearable @change="checkDataChange(i)">
              <el-option label="固定" value="GD"></el-option>
              <el-option label="效验" value="XY"></el-option>
              <el-option v-for="(variate,index) in dataForm.extenddata.variates" :key="index" :label="'变量'+(index+1)" :value="'V'+index"></el-option>
            </el-select>
            <el-checkbox v-model="dataForm.extenddata.checkeds[i]" :disabled="dataForm.extenddata.datas[i] == 'XY'"></el-checkbox>
            &nbsp;&nbsp;<el-button type="text" @click="deleteItem(i)"><i class="el-icon-delete"></i></el-button>
          </div>
          <div class="e-col">
            <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="dataForm.extenddata.datas.push('')"></el-button>
          </div>
        </div>
      </el-form-item>
      
      <el-form-item label="反馈格式" style="width:100%">
        <div class="e-row">
          <div class="e-col" v-for="(d,i) in dataForm.extenddata.fbdatas" :key="i">
            <el-input v-model="dataForm.extenddata.fbtitles[i]" clearable placeholder="标签"></el-input>
            <el-select v-model="dataForm.extenddata.fbdatas[i]" clearable @change="fbcheckDataChange(i)">
              <el-option label="固定" value="GD"></el-option>
              <el-option label="效验" value="XY"></el-option>
              <el-option v-for="(variate,index) in dataForm.extenddata.variates" :key="index" :label="'变量'+(index+1)" :value="'V'+index"></el-option>
            </el-select>
            <el-checkbox v-model="dataForm.extenddata.fbcheckeds[i]" :disabled="dataForm.extenddata.fbdatas[i] == 'XY'"></el-checkbox>
            &nbsp;&nbsp;<el-button type="text" @click="fbdeleteItem(i)"><i class="el-icon-delete"></i></el-button>
          </div>
          <div class="e-col">
            <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="dataForm.extenddata.fbdatas.push('')"></el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="滑条格式" style="width:100%;margin-top: 20px;">
        <div class="e-row">
          <div class="e-col" v-for="(d,i) in dataForm.extenddata.htdatas" :key="i">
            <el-input v-model="dataForm.extenddata.httitles[i]" clearable placeholder="标签"></el-input>
            <el-select v-model="dataForm.extenddata.htdatas[i]" clearable @change="checkDataChange(i)">
              <el-option label="滑条" value="HT"></el-option>
              <el-option label="CRC效验" value="CR"></el-option>
              <el-option label="固定" value="GD"></el-option>
              <el-option v-for="(variate,index) in dataForm.extenddata.variates" :key="index" :label="'变量'+(index+1)" :value="'V'+index"></el-option>
            </el-select>
            <el-button type="text" @click="htdeleteItem(i)"><i class="el-icon-delete"></i></el-button>
          </div>
          <div class="e-col">
            <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="dataForm.extenddata.htdatas.push('')"></el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="滑条反馈格式" style="width:100%">
        <div class="e-row">
          <div class="e-col" v-for="(d,i) in dataForm.extenddata.htBackdatas" :key="i">
            <el-input v-model="dataForm.extenddata.htBacktitles[i]" clearable placeholder="标签"></el-input>
            <el-select v-model="dataForm.extenddata.htBackdatas[i]" clearable @change="htBackcheckDataChange(i)">
              <el-option label="固定" value="GD"></el-option>
              <el-option label="效验" value="XY"></el-option>
              <el-option v-for="(variate,index) in dataForm.extenddata.variates" :key="index" :label="'变量'+(index+1)" :value="'V'+index"></el-option>
            </el-select>
            <el-checkbox v-model="dataForm.extenddata.htBackcheckeds[i]" :disabled="dataForm.extenddata.htBackdatas[i] == 'XY'"></el-checkbox>
            &nbsp;&nbsp;<el-button type="text" @click="htBackdeleteItem(i)"><i class="el-icon-delete"></i></el-button>
          </div>
          <div class="e-col">
            <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="dataForm.extenddata.htBackdatas.push('')"></el-button>
          </div>
        </div>
      </el-form-item>
      <div style="display:flex;">
        <div style="width:50%">
          <el-form-item label="效验设置">
            <el-select v-model="dataForm.extenddata.type" >
              <!-- <el-option label="CRC" value="crc"></el-option>
              <el-option label="华尔思效验和" value="sum"></el-option>
              <el-option label="德姆瑞效验" value="dmr"></el-option>
              <el-option label="视科效验" value="shk"></el-option> -->
              <el-option v-for="(option,index) in optionsData.checkALG || []" 
                    :key="index" :label="option.name" :value="option.data.code"></el-option>
            </el-select>
            {{checkDesc}}
          </el-form-item>
          <el-form-item v-for="(variate,index) in dataForm.extenddata.variates" :key="index" :label="'变量'+(index+1)" style="width:100%">
            <el-input v-model="variate.name" style="width:200px" placeholder="变量标签"></el-input> 
            <el-button type="text" size="small" @click="delVar(index)">删除</el-button>
          </el-form-item>
          <div style="padding-left:100px">
            <el-button  size="small" @click="addVar">添加变量</el-button>
          </div>
          
          
        </div>
        <!-- <div style="width:100%">
          <el-form-item label="反馈效验设置">
            <el-select v-model="dataForm.extenddata.fbtype" >
              <el-option label="CRC" value="crc"></el-option>
              <el-option label="华尔思效验和" value="sum"></el-option>
              <el-option label="德姆瑞效验" value="dmr"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-for="(variate,index) in dataForm.extenddata.fbvariates" :key="index" :label="'反馈变量'+(index+1)" style="width:100%">
            <el-input v-model="variate.name" style="width:200px" placeholder="变量标签"></el-input> 
            <el-button type="text" size="small" @click="addfbVar">添加</el-button><el-button type="text" size="small" @click="delfbVar(index)">删除</el-button>
          </el-form-item>
        </div> -->
      </div>
    </div>
    
    
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import uploadImg from '@/components/upload-img'
  export default {
    components: {
      uploadImg
    },
    data () {
      return {
        visible: false,
        dataForm: {},
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ]
        },
        dtData: null, // 类别数据
        optionsData: {}
      }
    },
    computed: {
      checkDesc: function() {
        for (var item of this.optionsData.checkALG) {
          if (item.data.code === this.dataForm.extenddata.type) {
            return item.data.desc
          }
        }
        return ''
      }
    },
    methods: {
      init (id) {
        this.dataForm = {
          name: '',
          deviceType: '',
          remark: '',
          sort: '',
          keytype: '',
          link: '',
          isextend: false,
          extenddata: {
            datas: [null], // 数据位
            htdatas: [null], // 滑条数据
            checkeds: [false], // 选中状态
            type: 'crc', // 计算类型
            variates: [{}], // 变量
            titles: [''],
            httitles: [''],
            fbdatas: [null], // 数据位
            htBackdatas: [null], //滑条反馈数据
            fbcheckeds: [false], // 选中状态
            htBackcheckeds: [false], //滑条反馈选中状态
            fbtype: 'crc', // 计算类型
            fbvariates: [{}], // 变量
            fbtitles: [''],
            htBacktitles: [''], //滑条反馈title
          }
        }
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          // this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/bus/busbrand/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.busBrand.name
                this.dataForm.deviceType = data.busBrand.deviceType || ''
                this.dataForm.remark = data.busBrand.remark
                this.dataForm.sort = data.busBrand.sort
                this.dataForm.keytype = data.busBrand.keytype || ''
                this.dataForm.link = data.busBrand.link
                this.dataForm.img = data.busBrand.img
                // eslint-disable-next-line eqeqeq
                this.dataForm.isextend = data.busBrand.isextend == 1
                this.dataForm.extenddata = Object.assign({
                  datas: [null], // 数据位
                  htdatas: [null], // 滑条数据
                  checkeds: [false], // 选中状态
                  type: 'crc', // 计算类型
                  variates: [{}], // 变量
                  titles: [''],
                  httitles: [''],
                  fbdatas: [null], // 数据位
                  htBackdatas: [null], //滑条反馈数据
                  fbcheckeds: [false], // 选中状态
                  htBackcheckeds: [false], //滑条反馈选中状态
                  fbtype: 'crc', // 计算类型
                  fbvariates: [{}], // 变量
                  fbtitles: [''],
                  htBacktitles: [''], //滑条反馈title
                }, JSON.parse(data.busBrand.extenddata || '{}'))
                console.log(this.dataForm.extenddata)
              }
            })
          }
        })
      },
      getDtdata() { // 获取类别数据
        if (!this.dtData) {
          this.$http({
            url: this.$http.adornUrl(`/bus/busdata/list/deviceType`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              let dtData = []
              for (var row of data.list) {
                row.data = JSON.parse(row.data)
                dtData.push({title: row.name, id: row.id})
              }
              this.dtData = dtData
            }
          })
        }
        return this.dtData || []
      },
      getData(code) {
        this.$http({
          url: this.$http.adornUrl('/bus/busdata/list/' + code),
          method: 'post',
          data: this.$http.adornData({})
        }).then(({data}) => {
          if (data && data.code === 0) {
            for (var row of data.list) {
              row.data = JSON.parse(row.data)
            }
            this.$set(this.optionsData, code, data.list)
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/bus/busbrand/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.name,
                'deviceType': this.dataForm.deviceType || 0,
                'remark': this.dataForm.remark,
                'sort': this.dataForm.sort,
                'keytype': this.dataForm.keytype,
                'link': this.dataForm.link,
                'img': this.dataForm.img,
                'isextend': this.dataForm.isextend ? 1 : 0,
                'extenddata': JSON.stringify(this.dataForm.extenddata)
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      checkDataChange(i) {
        if (this.dataForm.extenddata.datas[i] === 'XY') {
          this.dataForm.extenddata.checkeds[i] = false
        }
      },
      addVar() {
        this.dataForm.extenddata.variates.push({})
      },
      delVar(i) {
        this.dataForm.extenddata.variates.splice(i, 1)
      },
      deleteItem(i) {
        this.dataForm.extenddata.datas.splice(i, 1)
        this.dataForm.extenddata.checkeds.splice(i, 1)
        this.dataForm.extenddata.titles.splice(i, 1)
      },
      fbcheckDataChange(i) {
        if (this.dataForm.extenddata.fbdatas[i] === 'XY') {
          this.dataForm.extenddata.fbcheckeds[i] = false
        }
      },
      htBackcheckDataChange(i){
        if (this.dataForm.extenddata.htBackdatas[i] === 'XY') {
          this.dataForm.extenddata.htBackcheckeds[i] = false
        }
      },
      fbdeleteItem(i) {
        this.dataForm.extenddata.fbdatas.splice(i, 1)
        this.dataForm.extenddata.fbcheckeds.splice(i, 1)
        this.dataForm.extenddata.fbtitles.splice(i, 1)
      },
      htdeleteItem(i){
        this.dataForm.extenddata.htdatas.splice(i, 1)
        this.dataForm.extenddata.htcheckeds.splice(i, 1)
        this.dataForm.extenddata.httitles.splice(i, 1)
      },
      htBackdeleteItem(i){
        this.dataForm.extenddata.htBackdatas.splice(i, 1)
        this.dataForm.extenddata.htBackcheckeds.splice(i, 1)
        this.dataForm.extenddata.htBacktitles.splice(i, 1)
      },
      addfbVar() {
        this.dataForm.extenddata.fbvariates.push({})
      },
      delfbVar(i) {
        this.dataForm.extenddata.fbvariates.splice(i, 1)
      }
    },
    mounted() {
      this.getData('keyType')
      this.getData('checkALG')
    }
  }
</script>
<style lang="scss" scoped>
.brand{
  ::v-deep .el-dialog{
    width: 70%;
  }
  ::v-deep .el-form{
    display: flex;
    flex-wrap: wrap;
    .el-form-item{
      min-width: 50%;
      // .el-select{
      //   width: 100%;
      // }
    }
  }
  .e-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .e-col{
      max-width: 100px;
      margin-right: 5px;
      text-align: center;
    }
  }
}
</style>
