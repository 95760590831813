<template>
  <div class="main">
    <a href="javascript:" v-popover:popover5>预览</a>
    <el-popover ref="popover5" @show="show">
      <img v-if="value && value.img" width="400" :src="$imgPath+value.img">
      <img v-else-if="btnClazzImg" width="400" :src="$imgPath+btnClazzImg">
      <div v-else v-loading="loading" class="menus">
        <div class="title" v-if="title">{{title}}</div>
        <div v-for="(btn,index) in btns" :key="index" class="btn">
          {{btn.name}}
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
  export default {
    props: ['value', 'title', 'btnClazzImg'],
    data () {
      return {
        loading: true,
        btns: []
      }
    },
    methods: {
      show() {
        if (this.value && !this.value.img) {
          this.$http({
            url: this.$http.adornUrl('/bus/busbuttons/getListByPid'),
            method: 'post',
            data: this.value.id
          }).then(({data}) => {
            this.loading = false
            if (data && data.code === 0) {
              this.btns = data.list
            }
          })
        }
      }
    }
  }
</script>
<style scoped lang="scss">
.menus{
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  text-align: center;
  line-height: 40px;
  background-color: #212121;
  color: #E4D060;
  padding: 10px 0;
  .title{
    width: 100%;
  }
  .btn{
    width: 50%;
  }
}
</style>
