<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible" class="button">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
    </el-form-item>
    <el-form-item label="预览设置" prop="img" v-if="$route.query.type == 1">
      <label v-if="!dataForm.img">系统默认</label>
      <upload-img v-model="dataForm.img" style="display: inline-block;"/>
      <el-button type="text" size="small" @click="dataForm.img = ''">重置</el-button>
    </el-form-item>
    <el-form-item label="自定义" v-if="brandData.isextend == 1">
      <el-switch v-model="dataForm.isCustom"></el-switch>
    </el-form-item>
    <div style="display:flex">
    <input-vars v-show="!dataForm.isCustom" v-model="dataForm.extenddata.vars" :brand-data="brandData"></input-vars>
    <!-- <input-vars v-show="!dataForm.isCustom" v-model="dataForm.extenddata.fbvars" :brand-data="brandData" is-feedback></input-vars> -->
    </div>
    <el-form-item label="自动CRC" v-if="dataForm.isCustom">
      <el-switch v-model="dataForm.isAutoCrc" @change="autoChange()"></el-switch>
    </el-form-item>
    <el-form-item label="默认值(关)" prop="commandbytes" v-if="$route.query.type == 0">
      <input-codes v-model="dataForm.commandbytes" :auto-crc="dataForm.isAutoCrc" 
      :is-custom="dataForm.isCustom" :brand-data="brandData"></input-codes>
    </el-form-item>
    <el-form-item label="默认值(开)" prop="commandbytes1" v-if="$route.query.type == 0">
      <input-codes v-model="dataForm.commandbytes1" :auto-crc="dataForm.isAutoCrc" 
      :is-custom="dataForm.isCustom" :brand-data="brandData"></input-codes>
    </el-form-item>
    <el-form-item label="开启时长按" prop="longpressopen" v-if="$route.query.type == 0">
      <input-codes v-model="dataForm.longpressopen" :auto-crc="dataForm.isAutoCrc" 
      :is-custom="dataForm.isCustom" :brand-data="brandData"></input-codes>
    </el-form-item>
    <el-form-item label="关闭时长按" prop="longpressclose" v-if="$route.query.type == 0">
      <input-codes v-model="dataForm.longpressclose" :auto-crc="dataForm.isAutoCrc" 
      :is-custom="dataForm.isCustom" :brand-data="brandData"></input-codes>
    </el-form-item>
    <el-form-item label="反馈值关" prop="feedback0">
      <input-code v-model="dataForm.feedback0" :auto-crc="dataForm.isAutoCrc" is-feedback
      :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
    </el-form-item>
    <el-form-item label="反馈值开" prop="feedback1">
      <input-code v-model="dataForm.feedback1" :auto-crc="dataForm.isAutoCrc" is-feedback
      :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
    </el-form-item>
    <div v-if="$route.query.type == 1">
          <draggable v-model="dataForm.items">
          <div class="item" v-for="(item,index) in dataForm.items" :key="index" style="flex-wrap: wrap;">
            <div class="index">{{ index+1 }}</div>
            <!-- {{item}} -->
            <el-form-item label="按钮名称" style="flex: 0 0 auto;">
              <el-select v-model="item.name" filterable allow-create>
                <el-option v-for="(name,index) in getCndata()" :key="index" :label="name" :value="name"></el-option>
              </el-select>
            </el-form-item>
            <template v-if="item.commandbytes=='HT'">
              <el-form-item label="控制码" style="min-width:400px">
                <input-code v-model="item.extenddata.commandBytes" is-huatiao
                :is-custom="false" :brand-data="brandData"></input-code>
              </el-form-item>
              <div>
                  最小值 <el-input style="width:50px" v-model="item.extenddata.minValue" maxlength="2"></el-input>
                  &nbsp;&nbsp;&nbsp;&nbsp;最大值 <el-input style="width:50px" v-model="item.extenddata.maxValue" maxlength="2"></el-input>
                  &nbsp;&nbsp;&nbsp;&nbsp;初始值 <el-input style="width:50px" v-model="item.extenddata.initValue" maxlength="2"></el-input>
              </div>
              <el-form-item label="滑条样式" style="flex: 0 0 auto;">
                <el-select v-model="item.extenddata.htStyle" style="width: 80px;">
                  <el-option label="白" value="1"></el-option>
                  <el-option label="黄" value="2"></el-option>
                  <el-option label="红" value="3"></el-option>
                  <el-option label="绿" value="4"></el-option>
                  <el-option label="蓝" value="5"></el-option>
                  <el-option label="RGB" value="6"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="反馈码" style="min-width:400px" label-width="120px">
                <div slot="label">
                  <el-switch v-model="item.isFeedback" active-text="反馈码" @change="feedbackChange(item)"></el-switch>
                </div>
                <input-code v-if="item.isFeedback" v-model="item.feedback0" :auto-crc="dataForm.isAutoCrc" is-feedback
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
              </el-form-item>
            </template>
            <template v-else-if="item.commandbytes=='KG'">
              <el-form-item label="开码" style="min-width:400px">
                <input-code v-model="item.extenddata.open" :auto-crc="dataForm.isAutoCrc" 
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
              </el-form-item>
              <el-form-item label="关码" style="min-width:400px">
                <input-code v-model="item.extenddata.close" :auto-crc="dataForm.isAutoCrc" 
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
              </el-form-item>
              <el-form-item label="开反馈码" style="min-width:400px">
                <input-code v-model="item.extenddata.openBack" :auto-crc="dataForm.isAutoCrc" 
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
              </el-form-item>
              <el-form-item label="关反馈码" style="min-width:400px">
                <input-code v-model="item.extenddata.closeBack" :auto-crc="dataForm.isAutoCrc" 
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="控制码" style="min-width:400px">
                <input-codes v-model="item.commandbytes" :auto-crc="dataForm.isAutoCrc" 
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-codes>
              </el-form-item>
              <el-form-item label="反馈码" style="min-width:400px" label-width="120px">
                <div slot="label">
                  <el-switch v-model="item.isFeedback" active-text="反馈码" @change="feedbackChange(item)"></el-switch>
                </div>
                <input-code v-if="item.isFeedback" v-model="item.feedback0" :auto-crc="dataForm.isAutoCrc" is-feedback
                :is-custom="dataForm.isCustom" :brand-data="brandData"></input-code>
              </el-form-item>
            </template>
            <i class="el-icon-close" @click="delItem(index)"></i>
          </div>
          </draggable>
          <el-button type="primary" @click="addItem()">添加按钮</el-button>
          <el-button type="primary" @click="addItem('HT')">添加滑条</el-button>
          <el-button type="primary" @click="addItem('KG')">添加开关按钮</el-button>
        </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import CRC from '@/utils/CRC'
  import draggable from 'vuedraggable'
  import uploadImg from '@/components/upload-img'
  import inputCode from '@/components/input-code16'
  import inputCodes from '@/components/input-code16/Multiple'
  import inputVars from '@/components/input-vars'
  export default {
    components: {
      draggable,
      uploadImg,
      inputCode,
      inputCodes,
      inputVars
    },
    data () {
      return {
        visible: false,
        dataForm: {
          extenddata: {}
        },
        brandData: {},
        dataRule: {
          pid: [
            { required: true, message: '父级不能为空', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          isAutoCrc: [
            { required: true, message: '是否自动计算CRC不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌id不能为空', trigger: 'blur' }
          ],
          // img: [
          //   { required: true, message: '图片不能为空', trigger: 'blur' }
          // ],
          createuser: [
            { required: true, message: '创建人不能为空', trigger: 'blur' }
          ],
          createtime: [
            { required: true, message: '创建时间不能为空', trigger: 'blur' }
          ]
        },
        cnData: null // 按钮名称数据
      }
    },
    methods: {
      feedbackChange(item) {
        if (!item.isFeedback) { item.feedback0 = '' }
        this.$forceUpdate()
      },
      codeChange(code, obj) {
        obj = obj || this.dataForm
        var newValue = this.formatCode(obj[code])
        this.$set(obj, code, newValue)
        this.$set(obj, code + '2', newValue ? this.formatCode(CRC.ToModbusCRC16(newValue, true)) : '')
      },
      autoChange() {
        this.codeChange('commandbytes')
        this.codeChange('commandbytes1')
        this.codeChange('feedback0')
        this.codeChange('feedback1')
        this.codeChange('longpressopen')
        this.codeChange('longpressclose')
      },
      formatCode(value) {
        var newValue = ''
        value = value.replace(/\s*/g, '')
        for (var i = 0; i < value.length; i++) {
          if (i === value.length - 1 && i % 2 === 0) {
            newValue += '0' + value[i]
          } else { newValue += value[i] }
          if ((i + 1) % 2 === 0 && i < value.length - 1) {
            newValue += ' '
          }
        }
        return newValue
      },
      getCndata() { // 获取按钮名称
        if (!this.cnData) {
          this.$http({
            url: this.$http.adornUrl(`/bus/busdata/list/commandName`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data && data.code === 0) {
              let cnData = []
              for (var row of data.list) {
                row.data = JSON.parse(row.data)
                cnData.push(row.name)
              }
              this.cnData = cnData
            }
          })
        }
        return this.cnData || []
      },
      // 添加条目
      addItem(initType='') {
        var items = Object.assign([], this.dataForm.items)
        items.push({name: '', commandbytes: initType, extenddata:{}})
        this.$set(this.dataForm, 'items', items)
      },
      delItem(index) {
        var items = Object.assign([], this.dataForm.items)
        items.splice(index, 1)
        this.$set(this.dataForm, 'items', items)
      },
      init (id) {
        this.dataForm = {
          id: 0,
          pid: '',
          name: '',
          commandbytes: '',
          commandbytes1: '',
          feedback0: '',
          feedback1: '',
          longpressopen: '',
          longpressclose: '',
          isAutoCrc: true,
          brandId: this.$route.query.brandid,
          img: '',
          isCustom: true,
          extenddata: {},
          items: [{}]
        }
        this.dataForm.id = id || 0
        this.visible = true

        this.$nextTick(() => {
          // this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/bus/busbuttons/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.brandData = data.busButtons.brandEntity
                this.brandData.extenddata = JSON.parse(this.brandData.extenddata) || {}
                this.dataForm.pid = data.busButtons.pid
                this.dataForm.name = data.busButtons.name
  
                this.dataForm.commandbytes = data.busButtons.commandbytes
                this.dataForm.commandbytes1 = data.busButtons.commandbytes1
                this.dataForm.feedback0 = data.busButtons.feedback0
                this.dataForm.feedback1 = data.busButtons.feedback1
                this.dataForm.longpressopen = data.busButtons.longpressopen
                this.dataForm.longpressclose = data.busButtons.longpressclose
                this.dataForm.isAutoCrc = data.busButtons.isAutoCrc === 1
                this.dataForm.brandId = data.busButtons.brandId
                this.dataForm.img = data.busButtons.img
                this.dataForm.isCustom = data.busButtons.isextend !== 1
                this.dataForm.extenddata = JSON.parse(data.busButtons.extenddata || '{}')

                if (data.busButtons.items && data.busButtons.items.length > 0) {
                  this.dataForm.items = data.busButtons.items
                  for (var item of this.dataForm.items) {
                    if (item.feedback0) item.isFeedback = true
                    item.extenddata = JSON.parse(item.extenddata || '{}')
                  }
                }
              }
            })
          } else {
            this.$http({
              url: this.$http.adornUrl(`/bus/busbrand/info/${this.$route.query.brandid}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              this.brandData = data.busBrand
              this.brandData.extenddata = JSON.parse(this.brandData.extenddata) || {}
              if (this.brandData.isextend === 1) {
                this.dataForm.isCustom = false
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        var type = this.$route.query.type
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (Number(type) === 1) {
              for (var item of this.dataForm.items) {
                item.commandbytes = this.dataForm.isAutoCrc ? this.formatCode((item.commandbytes || '') + (item.commandbytes2 || '')) : item.commandbytes
              }
            }
            var items = Object.assign([], this.dataForm.items)
            for (var item of items) {
              item.extenddata = JSON.stringify(item.extenddata)
            }
            this.$http({
              url: this.$http.adornUrl(`/bus/busbuttons/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'pid': this.dataForm.pid || this.$route.query.type,
                'name': this.dataForm.name,
                'commandbytes': this.dataForm.commandbytes,
                'commandbytes1': this.dataForm.commandbytes1,
                'feedback0': this.dataForm.feedback0,
                'feedback1': this.dataForm.feedback1,
                'longpressopen': this.dataForm.longpressopen,
                'longpressclose': this.dataForm.longpressclose,
                'isAutoCrc': this.dataForm.isAutoCrc ? 1 : 0,
                'brandId': this.dataForm.brandId,
                'img': this.dataForm.img,
                'items': items,
                'isextend': this.dataForm.isCustom ? 0 : 1,
                'extenddata': JSON.stringify(this.dataForm.extenddata)
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      }
    }
  }
</script>
<style scoped lang="scss">
.button{
  ::v-deep .el-dialog{
    width: 70%;
  }
  .item{
    display: flex;
    background-color: #f2f6fc;
    padding-top: 20px;
    margin-bottom: 10px;
    position: relative;
    padding-right: 30px;
    .el-icon-close{
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 18px;
    }
    .index{
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
}
</style>
