<template>
  <div>
    <el-upload :action="url" :on-success="successHandle" :show-file-list="false">
      {{value}}
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
  export default {
    props: ['value','path'],
    data () {
      return {
        url: this.$http.adornUrl(`/sys/oss/uploadFile?path=${this.path}&token=${this.$cookie.get('token')}`)
      }
    },
    methods: {
      // 上传成功
      successHandle (response, file, fileList) {
        if (response && response.code === 0) {
          this.value = response.filename
          this.$emit('input', response.filename)
        } else {
          this.$message.error(response.msg)
        }
      }
    }
  }
</script>
