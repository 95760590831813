<template>
  <div>
    <el-form class="setting-form" :rules="dataRule" ref="form" :model="data" label-width="90px">
      <div class="head">
          <el-form-item :label="headTitles[0] || '客户'" prop="custom" class="required">
              <el-select v-model="data.custom" >
                  <el-option v-for="(option,index) in optionsData.custom || []" 
                  :key="index" :label="option.name" :value="option.id"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="headTitles[1] || '主题名'" prop="title" class="required">
              <el-input v-model="data.title" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item :label="headTitles[2] || '支持系统'" prop="supportSystem" class="required">
              <el-select v-model="data.supportSystem" value-key="id" 
                @change="supportSystemChange(data.supportSystem)">
                  <el-option v-for="(option,index) in optionsData.supportSystem || []" 
                  :key="index" :label="option.name" :value="option"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="headTitles[3] || '唤醒设备'" prop="wakeupToHomeCommand">
              <el-select v-model="data.wakeupToHomeCommand" filterable allow-create @change="formatCode(data,'wakeupToHomeCommand')">
                <el-option v-for="(option,index) in optionsData.wakeupToHomeCommandCommon || []" 
                  :key="index" :label="option.name" :value="option.data.value"></el-option>
                  <el-option v-for="(option,index) in optionsData.wakeupToHomeCommand || []" 
                  :key="index" :label="option.name" :value="option.data.value"></el-option>
                  
              </el-select>
          </el-form-item>
          
          <el-form-item :label="headTitles[4] || '分类页面'" prop="classPage" class="required">
              <el-select v-model="data.classPage" value-key="name">
                  <el-option v-for="(option,index) in optionsData.classPage || []" 
                  :key="index" :label="option.name" :value="option"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="headTitles[5] || '机器码'" prop="machineCode">
            <el-input v-model="data.machineCode" :disabled="isDisableMachine"></el-input>
          </el-form-item>
          <el-form-item :label="headTitles[6] || '本机ID'" prop="idData">
            <el-select v-model="data.idData" filterable allow-create @change="formatCode(data,'idData')">
              <el-option label="无" value=""></el-option>
              <el-option v-for="i in 10"  :key="i" :label="i>9?'10':('0'+i)" :value="i>9?'10':('0'+i)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="headTitles[7] || '编码'" prop="idData">
            <el-select v-model="data.charset">
              <el-option label="UTF-8" value="UTF-8"></el-option>
              <el-option label="GBK" value="GBK"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item  label="设备服务">
            <websocket ref="websocket" :machineCode="data.machineCode" @stateChange="state=>websokcetState=state"></websocket>
          </el-form-item> -->
          <el-form-item  label="" v-if="$store.state.user.userInfo.linkWxs">
            <el-switch v-model="data.isWxsCustomJson" active-text="分配到小程序账户" style="margin-left: -50px;"></el-switch>
          </el-form-item>
          
      </div>
      <el-table ref="settingTable" :data="data.list" class="setting-table">
        <el-table-column prop="index" type="index" width="50" header-align="center" align="center" :label="tableHeadTitles[0] || '序号'"></el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[1] || '房间'" width="120">
          <template slot-scope="scope">
            <el-select v-model="scope.row.room" value-key="name" filterable allow-create @visible-change="setFocus" :placeholder="(tableHeadTitles[1] || '房间')">
              <el-option v-for="(option,index) in optionsData.room || []" 
              :key="index" :label="option.name" :value="option.name"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[2] || '类别'" width="150">
          <template slot-scope="scope">
            <img v-if="scope.row.deviceType && scope.row.deviceType.data" class="label-img" 
                :src="$imgPath+scope.row.deviceType.data.icon" />
            <el-select class="required" v-model="scope.row.deviceType" value-key="name"  @change="deviceTypeChange(scope.row.deviceType,scope.$index)" :placeholder="(tableHeadTitles[2] || '类别')">
                <el-option v-for="(option,index) in optionsData.deviceType || []" 
                :key="index" :label="option.name" :value="option">
                    <span style="float: left"><img style="width:26px" :src="$imgPath+option.data.icon" /></span>
                    <span style="float: right;">{{option.name}}</span>
                </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[3] || '按钮名称'" width="180">
          <template slot-scope="scope">
            <img v-if="scope.row.commandName && scope.row.commandName.data" class="label-img"  
                  :src="$imgPath+scope.row.commandName.data.icon" />
            <img v-else-if="scope.row.deviceType && scope.row.deviceType.data" class="label-img" 
                  :src="$imgPath+scope.row.deviceType.data.icon">
            <el-select class="required" v-model="scope.row.commandName" value-key="name" filterable allow-create @visible-change="setFocus" :placeholder="'请选择'+(tableHeadTitles[3] || '按钮名称')">
                <el-option v-for="(option,index) in (optionsData.list[scope.$index].commandName || [scope.row.commandName])" 
                :key="index" :label="option && option.name" :value="option">
                  <span style="float: left"><img style="width:26px" v-if="option && option.data" :src="$imgPath+option.data.icon" /></span>
                  <span style="float: right;">{{option && option.name}}</span>
                </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[4] || '品牌'" >
          <template slot-scope="scope">
            <img v-if="scope.row.brand && scope.row.brand.img" class="label-img" 
                :src="$imgPath+scope.row.brand.img" />
            <el-select v-model="scope.row.brand" class="required" value-key="id" @change="brandChange(scope.row,scope.$index)" :placeholder="'请选择'+(tableHeadTitles[4] || '品牌')" remote>
                <el-option v-for="(option,index) in (optionsData.list[scope.$index].brand || [scope.row.brand])"
                :key="index" :label="option && option.name" :value="option">
                  <span style="float: left"><img style="width:26px" v-if="option" :src="$imgPath+option.img" /></span>
                  <span style="float: right;">{{option && option.name}}</span>
                </el-option>
            </el-select>
            <div v-if="scope.row.brand && scope.row.brand.link" class="item-right">
                <a :href="scope.row.brand.link" target="_blank">查看</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[5] || '数据源'" >
          <template slot-scope="scope">
            <div class="col-item" v-show="scope.row.keyType == 1 || scope.row.btnClazz == 1">
              <div class="col-item" v-if="!scope.row.isGjcj">
                  <el-select v-model="scope.row.commandBytes" class="required" value-key="id" 
                  multiple filterable allow-create @visible-change="setFocus" 
                  :placeholder="'请选择'+(tableHeadTitles[5] || '按钮数据')"
                  @change="btn1Change(scope.row.commandBytes)">
                      <el-option v-for="(option,index) in optionsData.list[scope.$index].buttons1 || scope.row.commandBytes"
                      :key="index" :label="option.name" :value="option"></el-option>
                  </el-select>
              </div>
              <div class="col-item ignore-elements" v-else style="justify-content: center;align-items: center;">
                <select-data v-model="scope.row.gjBtns" :keytype="scope.row.keyType" :system-id="data.supportSystem.id"
                :title="(scope.row.room || '')+((scope.row.brand && scope.row.brand.name) || '')+'高级场景设置'"></select-data>
              </div>
            </div>
            <div class="col-item" v-show="scope.row.keyType == 2 || scope.row.btnClazz == 2 || scope.row.btnClazz == 3">
              <div class="col-item" v-if="!scope.row.isGjcj">
                <div class="col-item" label="二级菜单">
                    <el-select v-model="scope.row.menus" value-key="id" placeholder="请选择二级菜单" class="required">
                        <el-option v-for="(option,index) in optionsData.list[scope.$index].buttons2 || [scope.row.menus]"
                        :key="index" :label="option && option.name" :value="option"></el-option>
                    </el-select>
                </div>
                <div v-if="scope.row.menus" class="item-right">
                    <menu-show v-model="scope.row.menus" :btn-clazz-img="scope.row.btnClazzImg"
                    :title="(scope.row.room || '')+((scope.row.commandName && scope.row.commandName.name) || scope.row.commandName)"></menu-show>
                </div>
              </div>
              <div class="col-item ignore-elements" v-else style="justify-content: center;align-items: center;">
                <select-data v-model="scope.row.gjBtns" :keytype="scope.row.keyType" :system-id="data.supportSystem.id"
                :title="(scope.row.room || '')+((scope.row.brand && scope.row.brand.name) || '')+'高级场景设置'"></select-data>
              </div>
            </div>
            <div class="col-item" v-if="scope.row.keyType == 3">
              <div class="col-item" label="按钮数据2">
                  <el-select v-model="scope.row.url" filterable allow-create @visible-change="setFocus" placeholder="请选择按钮数据2">
                      <el-option v-for="(option,index) in optionsData.url || []" 
                      :key="index" :label="option.name" :value="option.data.value">
                      </el-option>
                  </el-select>
              </div>
              <div v-if="scope.row.keyType == 3" class="item-right">
                <a :href="scope.row.url" target="_blank">预览</a>
              </div>
            </div>
            
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[6] || '变量复选'"  width="150">
          <template slot-scope="scope">
            <!-- {{scope.row.vars}} -->
            <select-vars class="required" v-if="!scope.row.isGjcj && scope.row.keyType == 1" v-model="scope.row.vars" :brand-data="scope.row.brand" :button-data="scope.row.commandBytes"></select-vars>
            <select-vars class="required" v-if="!scope.row.isGjcj && scope.row.keyType == 2" v-model="scope.row.vars" :brand-data="scope.row.brand" :button-data="scope.row.menus"></select-vars>
            <!-- <select-vars v-if="!scope.row.isGjcj && scope.row.keyType == 1" v-model="scope.row.fbvars" is-feedback :brand-data="scope.row.brand" :button-data="scope.row.commandBytes"></select-vars>
            <select-vars v-if="!scope.row.isGjcj && scope.row.keyType == 2" v-model="scope.row.fbvars" is-feedback :brand-data="scope.row.brand" :button-data="scope.row.menus"></select-vars> -->
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[7] || '备注'"  width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" clearable ></el-input>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" :label="tableHeadTitles[8] || '联动按钮'" width="150">
          <template slot-scope="scope">
            <select-data v-model="scope.row.linkage" :system-id="data.supportSystem && data.supportSystem.id" 
            :title="(scope.row.room || '')+((scope.row.brand && scope.row.brand.name) || '')+'联动设置'"></select-data>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" width="100" :label="tableHeadTitles[9] || '操作'">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="testSend(scope.$index)" :disabled="websokcetState!='connect'">发送</el-button>
            <el-button type="text" size="small" @click="copyItem(scope.$index)">复制</el-button>
            <el-button type="text" size="small" @click="delItem(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <div style="margin-top:10px">
          <el-button type="primary" @click="addItem()">添加按钮</el-button>
          <el-button type="success" @click="dataFormSubmit()">保存配制</el-button>
          <el-button @click="$router.go(-1)">返回</el-button>
      </div>
  </el-form>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import clone from 'lodash/cloneDeep'
import Sortable from 'sortablejs'
import selectData from './bussetting-selectdata'
import menuShow from './bussetting-menushow'
import websocket from './bussetting-websocket'

import { formatCode } from '@/utils'
import selectVars from '@/components/select-vars'
export default {
  components: {
    draggable,
    selectData,
    selectVars,
    menuShow,
    websocket
  },
  data () {
    return {
      id: null,
      data: {
        list: [{}]
      },
        // 下拉数据
      optionsData: {
        list: [{}] // 条目中各自的下拉数据
      },
      dataRule: {
        custom: {required: true, message: '客户不能为空', trigger: 'blur'},
        title: {required: true, message: '主题名不能为空', trigger: 'blur'},
        supportSystem: {required: true, message: '关联系统不能为空', trigger: 'blur'},
        classPage: {required: true, message: '分类不能为空', trigger: 'blur'}
        // machineCode: {required: !this.isAuth('bus:bussetting:ignoreMachine'), message: '机器码不能为空', trigger: 'blur'}
      },
      isDisableMachine: false,
      websokcetState: null, 
    }
  },
  computed: {
    headTitles: function() {
      if (this.$store.state && this.$store.state.common.paramsConfig && this.$store.state.common.paramsConfig.setting_head) {
        return this.$store.state.common.paramsConfig.setting_head.split(',')
      }
      return []
    },
    tableHeadTitles: function() {
      if (this.$store.state && this.$store.state.common.paramsConfig && this.$store.state.common.paramsConfig.setting_table) {
        return this.$store.state.common.paramsConfig.setting_table.split(',')
      }
      return []
    }
  },
  methods: {
    setFocus(event) {
      if (event) {
        setTimeout(() => {
          var el = document.activeElement
          el.blur()
          el.focus()
        }, 0)
      }
    },
    init () {
      this.getCustomData()
      this.isDisableMachine = false
      // 新增
      this.data = {
        custom: null,
        title: null,
        supportSystem: null,
        classPage: null,
        machineCode: null,
        idData: '01',
        list: [{
          room: null,
          deviceType: null,
          commandName: null,
          brand: null,
          keyType: null,
          commandBytes: null,
          menus: null,
          url: null,
          vars: []
        }]
      }
      this.optionsData.list = [{
        commandName: []
      }] // 条目中各自的下拉数据

      this.id = this.$route.query.id || null

      if (this.id || this.$route.query.copyid) {
        this.$http({
          url: this.$http.adornUrl(`/bus/bussetting/info/${this.id || this.$route.query.copyid}`),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.data = JSON.parse(data.busSetting.data)
            this.supportSystemChange(this.data.supportSystem)
            this.optionsData.list = []
            for (var i in this.data.list) { // 关联类别改变后操作
              this.optionsData.list.push({})
              this.deviceTypeChange(this.data.list[i].deviceType, i)
              this.brandChange(this.data.list[i], i)
            }

            this.isDisableMachine = (this.id != null && data.busSetting.machineCode != null && data.busSetting.machineCode !== '' && !this.isAuth('bus:bussetting:ignoreMachine'))

            this.$nextTick(() => {
              this.setSort()
            })
          }
        })
      }
    },
    // 添加条目
    addItem() {
      this.optionsData.list.push({})
      this.data.list.push({room: null,
        deviceType: null,
        brand: null,
        commandName: null,
        keyType: null,
        commandBytes: null,
        menus: null,
        url: null,
        vars: []})
      this.setSort()
    },
    // 复制条目
    copyItem(index) {
      this.optionsData.list.push(clone(this.optionsData.list[index]))
      this.data.list.push(clone(this.data.list[index]))

      this.setSort()
    },
    delItem(index) {
      this.data.list.splice(index, 1)
      this.optionsData.list.splice(index, 1)
    },
    //测试发送
    testSend(index){
      this.$http({
        url: this.$http.adornUrl(`/bus/bussetting/testSend`),
        method: 'post',
        data: this.$http.adornData({
          'channelId': this.$refs.websocket.channelId,
          'item': JSON.stringify(this.data.list[index])
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '发送成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              // this.$router.go(-1)
            }
          })
        } else {
          this.$message.error(data.msg)
        }
      })
},
      // 表单提交
    dataFormSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/bus/bussetting/${!this.id ? 'save' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              'id': this.id,
              'custId': this.data.custom,
              'name': this.data.title,
              'machineCode': this.data.machineCode,
              'data': JSON.stringify(this.data),
              'isWxsCustomJson': this.data.isWxsCustomJson,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.msg && !this.id) {
                this.id = data.msg
              }
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  // this.$router.go(-1)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    // 获取下拉客户
    getCustomData(code) {
      this.$http({
        url: this.$http.adornUrl('/bus/buscustom/getList'),
        method: 'post'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$set(this.optionsData, 'custom', data.list)
        }
      })
    },
    // 支持系统改变后
    supportSystemChange(supportSystem) {
      this.$http({
        url: this.$http.adornUrl('/bus/busdata/list/wakeupToHomeCommand'),
        method: 'get',
        params: {
          'where': `"${supportSystem.name}"`
        }
      }).then(({data}) => {
        if (data && data.code === 0) {
          for (var row of data.list) {
            row.data = JSON.parse(row.data)
          }
          this.$set(this.optionsData, 'wakeupToHomeCommand', data.list)
        } else {
          this.$set(this.optionsData, 'wakeupToHomeCommand', [])
        }
      })
      for (var item of this.data.list) {
        if (item.deviceType) { this.getBrandData(this.data.supportSystem.id, item.deviceType.id, this.data.list.indexOf(item)) }
      }
    },
    // 类别改变后
    deviceTypeChange(deviceType, index) {
      if (typeof deviceType === 'object') {
        this.$http({
          url: this.$http.adornUrl('/bus/busdata/list/commandName'),
          method: 'get',
          params: {
            'where': `"deviceType":"${deviceType.data.value}"`
          }
        }).then(({data}) => {
          if (data && data.code === 0) {
            for (var row of data.list) {
              row.data = JSON.parse(row.data)
            }
            this.$set(this.optionsData.list[index], 'commandName', data.list)
          } else {
            this.$set(this.optionsData.list[index], 'commandName', [])
          }
        })

        this.getBrandData(this.data.supportSystem.id, deviceType.id, index)
      } else {
        this.$set(this.optionsData.list[index], 'commandName', [])
      }
    },
    // 品牌数据改变后
    brandChange(row, index) {
      var brand = row.brand
      var keytype = this.optionsData.keyType.find(item => item.id === row.brand.keytype)
      this.$set(row, 'keyType', keytype ? keytype.data.value : '')
      this.$set(row, 'isGjcj', keytype ? keytype.data.isGjcj : false)
      this.$set(row, 'btnClazz', keytype ? keytype.data.clazz : false)
      this.$set(row, 'btnClazzImg', keytype ? keytype.data.clazzImg : false)
      this.$http({
        url: this.$http.adornUrl('/bus/busbuttons/getListByBrandId'),
        method: 'post',
        data: brand.id
      }).then(({data}) => {
        if (data && data.code === 0) {
          var buttons1 = []
          var buttons2 = []
          for (var btn of data.list) {
            if (btn.pid === 0)buttons1.push(btn)
            else buttons2.push(btn)
          }
          this.$set(this.optionsData.list[index], 'buttons1', buttons1)
          this.$set(this.optionsData.list[index], 'buttons2', buttons2)
        } else {
          this.$set(this.optionsData.list[index], 'buttons1', [])
          this.$set(this.optionsData.list[index], 'buttons2', [])
        }
      })
    },
    // 获取下拉基础数据
    getData(code, where, list) {
      this.$http({
        url: this.$http.adornUrl('/bus/busdata/list/' + code),
        method: 'post',
        params: {
          'where': where
        }
      }).then(({data}) => {
        if (data && data.code === 0) {
          for (var row of data.list) {
            row.data = JSON.parse(row.data)
          }
          this.$set(this.optionsData, list || code, data.list)
        }
      })
    },
    // 获取支持系统数据
    getSystemData() {
      this.$http({
        url: this.$http.adornUrl('/bus/bussystem/getMySystem'),
        method: 'post'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$set(this.optionsData, 'supportSystem', data.list)
        }
      })
    },
    // 获取品牌数据
    getBrandData(systemId, deviceTypeId, index) {
      if (systemId && deviceTypeId) {
        this.$http({
          url: this.$http.adornUrl('/bus/busbrand/getMyBrandList'),
          method: 'get',
          params: {
            'systemId': systemId,
            'deviceTypeId': deviceTypeId
          }
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$set(this.optionsData.list[index], 'brand', data.list)
          }
        })
      }
    },
    setSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
        filter: '.ignore-elements',
        onEnd: evt => {
          this.$refs.settingTable.doLayout()
          this.$refs.settingTable.clearSort()
          let list = this.data.list
          const targetRow = list.splice(evt.oldIndex, 1)[0]
          list.splice(evt.newIndex, 0, targetRow)
          const optionsDataListRow = this.optionsData.list.splice(evt.oldIndex, 1)[0]
          this.optionsData.list.splice(evt.newIndex, 0, optionsDataListRow)
          this.data.list = []
          this.$nextTick(() => {
            this.data.list = list
          })
        }
      })
    },
    btn1Change(v) {
      for (var item of v) {
        if (typeof item === 'string') {
          this.$set(v, v.indexOf(item), formatCode(item))
        }
      }
    },
    formatCode(data, key) {
      this.$set(data, key, formatCode(data[key]))
    }

  },
  mounted() {
    this.getSystemData()
    // this.getData('supportSystem')
    this.getData('room')
    this.getData('classPage')
    this.getData('deviceType')
    this.getData('keyType')
    // this.getData('menus')
    this.getData('url')
    this.getData('wakeupToHomeCommand', '系统公用', 'wakeupToHomeCommandCommon') // 唤醒公用
  },
  activated() {
    this.init()
  }
}
</script>
<style scoped lang="scss">
.setting-form{
  .head{
    display: flex;
    flex-wrap: wrap;
    .el-form-item{
        width: 250px;
        margin-bottom: 10px;
    }
  }
  .item-right{
    padding: 3px;
    line-height: 30px;
  }
  .item{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background-color: #f5f7fa;
    padding: 28px 20px 0;
    margin-bottom: 15px;
  }
  .el-icon-close{
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
  }
  .icon-copy{
    position: absolute;
    right: 30px;
    top: 5px;
    font-size: 18px;
  }
  .xuhao{
    position: absolute;
    left: 10px;
    top: 5px;
    font-size: 14px;
  }
}
.setting-table{
  width: 100%;
  ::v-deep .el-table__body .cell{
    display: flex;
    align-items: center;
    .el-select{
      flex: 1;
    }
    .col-item{
      flex: 1;
      display: flex;
    }
    .label-img{
      width: 26px;
      margin-right: 5px;
    }
  }
}

</style>
<style lang="scss">
.required{
  input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color:    red;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    red;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    red;
  }
  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:   red;
  }
  input::-ms-input-placeholder { /* Microsoft Edge */
    color:    red;
  }
}
</style>
