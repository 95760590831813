<template>
  <div class="vars">
    <!-- {{buttonData}} -->
    <!-- {{variates}} -->
    <el-select v-for="(v,index) in variates" :key="index" v-model="value[index]" 
    filterable allow-create :placeholder="v.name" @change="selectChange">
      <el-option v-for="(item,i) in varOptions[index]" :key="i" :label="v.name+item" :value="item"></el-option>
    </el-select>
  </div>
</template>

<script>
import { formatCode } from '@/utils'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    brandData: {
      type: Object
    },
    buttonData: {
      type: [Object, Array]
    },
    isFeedback: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    buttonData: function(v) {
      this.buttonDataChange()
    }
  },
  computed: {
    // variates: function() {
    //   if (this.brandData && this.brandData.isextend === 1 && (this.buttonData.isextend === 1 || this.buttonData[0].isextend === 1)) {
    //     if (this.isFeedback) {
    //       return JSON.parse(this.brandData.extenddata).fbvariates || []
    //     } else {
    //       debugger
    //       console.log(JSON.parse(this.brandData.extenddata).variates || [])
    //       return JSON.parse(this.brandData.extenddata).variates || []
    //     }
    //   }
    //   return []
    // }
  },
  data () {
    return {
      varOptions: [],
      variates: []
    }
  },
  mounted() {
    this.buttonDataChange()
  },
  methods: {
    getVariates() {
      if (this.brandData && this.brandData.isextend === 1 &&
      this.buttonData && (this.buttonData.isextend === 1 || (this.buttonData[0] && this.buttonData[0].isextend === 1))) {
        if (this.isFeedback) {
          return JSON.parse(this.brandData.extenddata).fbvariates || []
        } else {
          return JSON.parse(this.brandData.extenddata).variates || []
        }
      }
      return []
    },
    buttonDataChange() {
      this.variates = this.getVariates()
      this.varOptions = []
      var v = []
      for (var i in this.variates) {
        this.varOptions.push(this.getOptions(i))
        v.push(null)
      }
      if (v.length > 0 && this.value.length < 1) {
        this.$emit('input', v)
      }
    },
    selectChange(v) {
      for (var item of this.value) {
        if (typeof item === 'string') {
          this.$set(this.value, this.value.indexOf(item), formatCode(item))
        }
      }
      this.$emit('input', this.value)
    },
    getOptions(index) {
      var varsName = 'vars'
      if (this.isFeedback)varsName = 'fbvars'
      if (this.buttonData) {
        if (this.buttonData instanceof Array) {
          if (this.buttonData[0] && this.buttonData[0].extenddata) {
            var extenddata2 = JSON.parse(this.buttonData[0].extenddata)
            return this.getArea(extenddata2[varsName][index].minValue, extenddata2[varsName][index].maxValue)
          }
        } else {
          if (this.buttonData.extenddata) {
            var extenddata = JSON.parse(this.buttonData.extenddata)
            return this.getArea(extenddata[varsName][index].minValue, extenddata[varsName][index].maxValue)
          }
        }
      }
      return []
    },
    getArea(min, max) {
      var result = []
      if (min && max && min < max) {
        var min10 = parseInt(min, 16)
        var max10 = parseInt(max, 16)
        for (var i = 0; i <= (max10 - min10); i++) {
          result.push(formatCode((min10 + i).toString(16)))
        }
      } else {
        if (min)result.push(min)
        if (max)result.push(max)
      }
      return result
    }

  }
}
</script>
<style lang="scss" scoped>
.vars{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .el-select{
    flex: 1;
    min-width: 70px;
  }
}
</style>
