<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-button type="primary" @click="getDataList">刷新</el-button>
        <el-button type="primary" @click="showSettingPop">设置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%;">
      <el-table-column
        prop="ip"
        header-align="center"
        align="center"
        label="IP">
      </el-table-column>
      <el-table-column
        prop="sn"
        header-align="center"
        align="center"
        label="识别码">
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">加黑名单</el-button> -->
          <el-button type="text" size="small" @click="disconnect(scope.row.sn)">断开</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="设置" :close-on-click-modal="false" :visible.sync="showSetting">
      <el-form label-width="200px">
        <el-form-item label="心跳码" prop="heartCode">
          <input-code v-model="setting.heartCode" placeholder="16进制字符串"></input-code> 
        </el-form-item>
        <el-form-item label="心跳间隔(秒)" prop="heartInterval">
          <el-input v-model="setting.heartInterval" placeholder="心跳间隔" type="number"></el-input>
        </el-form-item>
        <el-form-item label="连接成功码" prop="heartCode">
          <input-code v-model="setting.successCode" placeholder="16进制字符串"></input-code> 
        </el-form-item>
        <el-form-item label="连接失败码" prop="heartCode">
          <input-code v-model="setting.failCode" placeholder="16进制字符串"></input-code> 
        </el-form-item>
        <el-form-item label="IP黑名单(英文逗号分隔)" prop="blackIp">
          <el-input v-model="setting.blackIps" placeholder="IP黑名单" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSetting = false">取消</el-button>
        <el-button type="primary" @click="ok()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import inputCode from '@/components/input-code16'
  export default {
    components:{inputCode},
    data () {
      return {
        dataForm: {
          key: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        showSetting: false,
        setting: {}
      }
    },
    activated () {
      this.getDataList()
    },
    methods: {
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/bus/yun/list'),
          method: 'get',
          params: this.$http.adornParams({
            'key': this.dataForm.key
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.list
          } else {
            this.dataList = []
          }
          this.dataListLoading = false
        })
      },
      disconnect(sn){
        this.$confirm(`确定要断开SN:${sn}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataListLoading = true
          this.$http({
            url: this.$http.adornUrl('/bus/yun/diconnect'),
            method: 'get',
            params: this.$http.adornParams({
              'sn': sn
            })
          }).then(({data}) => {
            this.dataListLoading = false
            this.getDataList()
          })
        })
        
      },
      showSettingPop(){
        this.loadSetting()
        this.showSetting = true
      },
      loadSetting(){
        this.$http({
          url: this.$http.adornUrl('/bus/yun/getSetting'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            data.setting.blackIps = (data.setting.blackIp||[]).join(',')
            // data.setting.blackSns = (data.setting.blackSn||[]).join(',')
            this.setting = data.setting
          } else {
            this.setting = {}
          }
        })
      },
      ok(){
        this.setting.blackIp = (this.setting.blackIps||'').split(',')
        // this.setting.blackSn = (this.setting.blackSns||'').split(',')
        this.$http({
          url: this.$http.adornUrl('/bus/yun/updateSetting'),
          method: 'post',
          data: this.$http.adornData(this.setting, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.showSetting = false
          }
        })
      }
    }
  }
</script>
