<template>
  <div>
    <el-popover  placement="top-start" trigger="click" width="500" title="字符串组件">
      <div>
        <el-input v-model="value.value" placeholder="请输入字符串"></el-input> 
      </div>
      <el-tag slot="reference" type="info" :style="{color:'#FFF'}" :color="value.color" closable @close="$emit('delete',value)">{{ value.value||value.name }}</el-tag>
    </el-popover>
  </div>
</template>
<script>
  import inputCode from '@/components/input-code16'
  export default {
    props: ['value'],
    components:{inputCode},
    data () {
      return {
      }
    }
  }
</script>
<style lang="scss" scoped>
.el-tag{
  cursor: pointer;
}
</style>