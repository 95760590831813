<template>
  <div class="login-page">
    <div class="login-main">
      <h3 class="login-title">注册</h3>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" status-icon
      label-width="100px">
        <el-form-item prop="userName" label="手机">
          <el-input v-model="dataForm.userName" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="姓名">
          <el-input v-model="dataForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item prop="pusername" label="服务商账号">
          <el-input v-model="dataForm.pusername" placeholder="请输入服务商账号"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input v-model="dataForm.password" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="password2" label="确认密码">
          <el-input v-model="dataForm.password2" type="password" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="machineCode" label="注册码">
          <el-input v-model="dataForm.machineCode" type="password" placeholder="请输入注册码"></el-input>
        </el-form-item>
        <div>
          <el-button class="login-btn-submit" type="primary" @click="dataFormSubmit()">确认</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { isMobile } from '@/utils/validate'
  export default {
    data () {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.dataForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      var validateMobile = (rule, value, callback) => {
        if (!isMobile(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      }
      return {
        dataForm: {
          userName: '',
          password: '',
          uuid: '',
          captcha: '',
          type: '1'
        },
        dataRule: {
          userName: [
            { required: true, message: '手机不能为空', trigger: 'blur' },
            { validator: validateMobile, trigger: 'blur' }
          ],
          name: [
            { required: true, message: '姓名不能为空', trigger: 'blur' }
          ],
          pusername: [
            { required: true, message: '服务商账号不能为空', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '密码不能为空', trigger: 'blur' }
          ],
          password2: [
            { required: true, message: '确认密码不能为空', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' }
          ],
          machineCode: [
            { required: true, message: '注册码不能为空', trigger: 'blur' }
          ]
        },
        captchaPath: ''
      }
    },
    created () {
      this.getCaptcha()
    },
    methods: {
      // 提交表单
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl('/sys/register'),
              method: 'post',
              data: this.$http.adornData({
                'username': this.dataForm.userName,
                'name': this.dataForm.name,
                'pusername': this.dataForm.pusername,
                'password': this.dataForm.password,
                'machineCode': this.dataForm.machineCode
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '注册成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.$router.push('/login')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .login-page{
    width: 100%;
  }
  .login-main {
    margin: 100px auto;
    background-color: #ebe7e8;
    max-width: 400px;
    padding: 20px;
  }
  .login-title {
    font-size: 16px;
  }
  .login-captcha {
    overflow: hidden;
    > img {
      width: 100%;
      cursor: pointer;
    }
  }
  .login-btn-submit {
    width: 100%;
  }
</style>
