<template>
  <div v-if="brandData && brandData.isextend == 1 && brandData.extenddata" style="width:100%">
    <el-form-item v-for="(v,index) in variates" :key="index" :label="(isFeedback?'反馈':'')+('变量'+(index+1))">
        <el-tag>{{v.name}}</el-tag>
        &nbsp;&nbsp;&nbsp;&nbsp;最小变量值 <el-input style="width:50px" v-model="v.minValue" maxlength="2" @change="minChange(v)"></el-input>
        &nbsp;&nbsp;&nbsp;&nbsp;最大变量值 <el-input style="width:50px" v-model="v.maxValue" maxlength="2" @change="maxChange(v)"></el-input>
    </el-form-item>
  </div>
</template>

<script>
import { is16Hex } from '@/utils/validate'
import { formatCode } from '@/utils'
export default {
  props: {
    value: {
      type: Array
    },
    brandData: {
      type: Object
    },
    isFeedback: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function(v) {
      if (v) {
        for (var i in this.variates) {
          if (v[i] && this.variates !== v) {
            this.$set(this.variates[i], 'minValue', v[i].minValue)
            this.$set(this.variates[i], 'maxValue', v[i].maxValue)
          }
        }
      }
    }
  },
  computed: {
    variates: function() {
      return this.isFeedback ? this.brandData.extenddata.fbvariates : this.brandData.extenddata.variates
    }
  },
  data () {
    return {
    }
  },
  methods: {
    minChange(v) {
      if (!is16Hex(v.minValue)) {
        this.$set(v, 'minValue', '00')
      } else {
        this.$set(v, 'minValue', formatCode(v.minValue))
      }
      this.sub()
    },
    maxChange(v) {
      if (!is16Hex(v.maxValue)) {
        this.$set(v, 'maxValue', 'FF')
      } else {
        this.$set(v, 'maxValue', formatCode(v.maxValue))
      }
      this.sub()
    },
    sub() {
      this.$emit('input', this.variates)
    }

  }
}
</script>
<style lang="scss" scoped>

</style>
