<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>
  export default {
    // mounted(){
    //   let socket = new WebSocket("ws://127.0.0.1:7080/renren-fast/ws");
    //   socket.onmessage = event=>{
    //     console.log("发消息了")
    //   };
    //   socket.onopen = event=>{
    //     console.log("webSocket已建立连接")
    //   };
    //   socket.onclose = event=>{
    //     console.log("webSocket已关闭连接")
    //   };
    //   socket.onerror = e=>{
    //     console.log(e)
    //   }
    // }
  }
</script>
