<template>
  <div>
    <!-- {{ value }} -->
    <el-row :gutter="10" >
      <el-col :span="4">
        <el-select v-model="value.code" @change="$set(value, 'data', null)">
          <el-option v-for="item in plugins" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-col>
      <el-col :span="20">
        <component :is="'plugin_'+value.code" v-model="value.data"></component>
      </el-col>
    </el-row>
    
  </div>
</template>
<script>
  import plugin_send from './plugin-send.vue'
  import plugin_sendback from './plugin-sendback.vue'
  import plugin_sendback_sv from './plugin-sendback-sv.vue'
  import plugin_delay from './plugin-delay.vue'
  import plugin_update from './plugin-update.vue'
  import plugins from './data'

  export default {
    props: ['value'],
    components: {
      plugin_send,
      plugin_sendback,
      plugin_sendback_sv,
      plugin_delay,
      plugin_update,
    },
    data () {
      return {
        plugins: plugins
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>