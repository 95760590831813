<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
      <el-checkbox v-model="dataForm.isLike">模糊匹配</el-checkbox>
    </el-form-item>
    <el-form-item label="别称" prop="alias">
      <el-input v-model="dataForm.alias" placeholder="别称"></el-input>
    </el-form-item>
    <el-form-item label="状态" prop="state">
      <el-select v-model="dataForm.state" >
        <el-option label="启用" :value="1"></el-option>
        <el-option label="禁用" :value="0"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="图片" prop="img">
      <upload-img v-model="dataForm.img"/>
    </el-form-item>
   <el-form-item label="JSON配置" prop="settingId">
      <el-select v-model="dataForm.settingId" placeholder="JSON配置" filterable clearable>
       <el-option v-for="(option,index) in settingList || []"
                    :key="index" :label="option.name" :value="option.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="设备密码" prop="pwd">
      <el-input v-model="dataForm.pwd" placeholder="清空代表无密码" clearable></el-input>
    </el-form-item>
    <el-form-item label="是否对比库存" prop="isStock">
      <el-switch v-model="dataForm.isStock"></el-switch>
    </el-form-item>
    <!-- <el-form-item label="识别码询问规则" prop="coderuleId">
      <el-select v-model="dataForm.coderuleId" placeholder="询问规则" >
        <el-option v-for="(option,index) in codeRuleList || []"
                    :key="index" :label="option.name" :value="option.id"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="事件规则" prop="events">
      <div style="margin-bottom: 10px;">
        小程序连接设备成功事件：
        <el-select v-model="dataForm.events.connectAfter" placeholder="交互规则" filterable>
          <el-option v-for="(option,index) in ruleList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
      </div>
      <div>
        小程序首页发送序列码事件：
        <el-select v-model="dataForm.events.sendSn" placeholder="交互规则" filterable>
          <el-option v-for="(option,index) in ruleList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
      </div>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import uploadImg from '@/components/upload-img'
  export default {
    components: {
      uploadImg
    },
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          name: '',
          isLike: false,
          alias: '',
          state: 1,
          img: '',
          settingId: '',
          pwd: null,
          isStock: false,
          coderuleId: null,
          events: {}
        },
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '状态不能为空', trigger: 'blur' }
          ],
          img: [
            // { required: true, message: '图片不能为空', trigger: 'blur' }
          ],
          // idruleId: [
          //   { required: true, message: 'ID询问规则不能为空', trigger: 'blur' }
          // ],
        },
        settingList: [],
        ruleList: [],
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.dataForm.isLike = false
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsdevices/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.wxsDevices.name
                if(this.dataForm.name.startsWith('%') && this.dataForm.name.endsWith('%')){
                  this.dataForm.isLike = true
                  this.dataForm.name = this.dataForm.name.substring(1,this.dataForm.name.length-1)
                }
                this.dataForm.alias = data.wxsDevices.alias
                this.dataForm.state = data.wxsDevices.state
                this.dataForm.img = data.wxsDevices.img
                this.dataForm.settingId = data.wxsDevices.settingId
                this.dataForm.pwd = data.wxsDevices.pwd
                this.dataForm.isStock = data.wxsDevices.isStock?true:false
                // this.dataForm.coderuleId = data.wxsDevices.coderuleId
                this.dataForm.events = JSON.parse(data.wxsDevices.events)||{}
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsdevices/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.isLike?`%${this.dataForm.name}%`:this.dataForm.name,
                'alias': this.dataForm.alias,
                'state': this.dataForm.state,
                'img': this.dataForm.img,
                'settingId': this.dataForm.settingId,
                'pwd': this.dataForm.pwd,
                'isStock': this.dataForm.isStock?1:0,
                // 'coderuleId': this.dataForm.coderuleId,
                'events': JSON.stringify(this.dataForm.events)
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      loadSettingList(){
        this.$http({
          url: this.$http.adornUrl('/bus/bussetting/all'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.settingList = data.list
          }
        })
      },
      loadRuleList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsrule/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.ruleList = data.page.list
          }
        })
      }
    },
    created() {
      this.loadSettingList()
      this.loadRuleList()
    }
  }
</script>
